import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import swDev from './swDev';
import axios from "axios";
import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

//axios.defaults.baseURL = 'http://localhost/admin/web/index.php?r=taquilla-rest/';
axios.defaults.baseURL = 'https://admin7816.entradaweb.com.ar/index.php?r=taquilla-rest/';
//axios.defaults.baseURL = 'https://backoffice7816.entradaweb.es/index.php?r=taquilla-rest/';
//axios.defaults.baseURL = 'http://test8762.admin.entradaweb.com.ar/index.php?r=taquilla-rest/';
axios.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem('token');

let deferredPrompt;
const addBtn = document.querySelector('.add-button');
const modal =  document.querySelector('.modal-promp-install');
const flagInstalled = document.querySelector('.installed');
const iphoneMessage = document.querySelector('.iphone-message');
const androidMessage = document.querySelector('.android-message');

modal.style.display = 'none';

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  modal.style.display = 'block';
  flagInstalled.value = 1;
  addBtn.addEventListener('click', () => {
    // hide our user interface that shows our A2HS button

    modal.style.display = 'none';
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User installed');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  });
});

function isThisDeviceRunningiOS(){
  if (['iPad Simulator', 'iPhone Simulator','iPod Simulator', 'iPad','iPhone','iPod'].includes(navigator.platform)) {
   return true;
  }
  // iPad on iOS 13  
  else if (navigator.userAgent.includes("Mac") && "ontouchend" in document){
    return true;
  }   
  else {
    return false;
  }
}

window.addEventListener('load', function() {
  window.history.pushState({}, '')
})

window.addEventListener('appinstalled', function(event){
  alert("Aplicación Instalada Correctamente");
});

window.onappinstalled = function() {
  console.log('Thank you for installing our app!');
};

if (!(window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true)) {
  if(isThisDeviceRunningiOS()){
    iphoneMessage.style.display = 'block';
    androidMessage.style.display = 'none';
    document.querySelector('.modal-install-footer').style.display = 'none';
    modal.style.display = 'block';
  }
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
//swDev();
reportWebVitals();
