import React, { Component } from 'react'
import {Button,Container, Row, Col, Image} from 'react-bootstrap'
import { Redirect } from 'react-router-dom';

export default class Inicio extends Component{

    state = {
       
    }

    componentDidMount(){
        
    }

    handleUserPassword = () => {
        this.setState({
            redirectLogin2: true
          })
    }


    handleAccessCode = () => {
        this.setState({
            redirectLogin: true
          })
    }

    renderRedirect = () => {
        if (this.state.redirectLogin) {
            return <Redirect to='/login' />
        }
        if (this.state.redirectLogin2) {
            return <Redirect to='/login2' />
        }
    }

    render(){
        let flagViewLogin = false;
            
        if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
            flagViewLogin = true;
        }

        let flagInstalled = true;
        if(document.querySelector('.installed').value == 0)
            flagInstalled = false;

        return (
            <><div className="login-wrap">
                {flagViewLogin && <Container>
                {this.renderRedirect()}
                    <Row>
                        <Col className="mt-5">
                            <Image src="/logotipo300.png" fluid width="60%" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-5">
                            <div className="d-grid gap-2">
                            <Button size="lg" className="btn btn-ew btn-naranja btn-block mb-4" onClick={this.handleAccessCode}>
                                Ingreso con Código de Acceso
                            </Button>
                            <Button size="lg" className="btn btn-ew btn-naranja btn-block" onClick={this.handleUserPassword}>
                                Ingreso con Usuario y Clave
                            </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="title-login">
                        <Col>
                            <h2>Control de Acceso</h2>
                            <small style={{"color": "#FFFFFF"}}>(V1.6)</small>
                        </Col>
                    </Row>
                </Container>}
                {!flagViewLogin && <Container>
                    <Row>
                        <Col className="mt-5">
                            <Image src="/logotipo300.png" fluid width="60%"/>
                        </Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                    </Row>
                    {!flagInstalled && <Row className="title-login-footer">
                        <Col>
                            <h2>Instalación del Control de Acceso</h2>
                        </Col>
                    </Row>}
                    {flagInstalled && <Row className="title-login">
                        <Col>
                            <h2>La App de Control de Acceso ya está instalada en este móvil.</h2>
                            <h2>Busca su ícono y ejecútala desde ahí.</h2>
                        </Col>
                    </Row>}
                </Container>}
            </div>
            </>
        );
    }
}